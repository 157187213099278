/* Container styles */
.ala-carte-container {
  display: grid;
  background-color: #331307; /* Dark brown background */
  grid-template-columns: repeat(2, 1fr); /* Two boxes per row */
  gap: 2rem;
  padding: 50px 10%;
  /* margin: 3%; */
  font-family: "Cactus Classical Serif", serif; /* Google Font */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  /* border-radius: 8px; */
}

/* Category box styles */
.ala-carte-category {
  text-align: center;
  margin-bottom: 2rem;
  padding: 16px; /* Add padding to create spacing inside the box */
  border: 1px solid #ae9256; /* Gold border */
  border-radius: 8px; /* Rounded corners */
  background-color: rgba(68, 35, 24, 0.8); /* Semi-transparent background */
}

.ala-carte-category h2 {
  color: #f3dc97; /* Gold text color */
  margin-bottom: 1rem;
  font-size: 20px;
  font-weight: 700; /* Bold font weight */
  grid-column: span 2;
  font-family: "Cinzel", serif; /* Elegant serif font */
}

/* Item list styles */
.ala-carte-category ul {
  list-style: none;
  padding: 0 10%;
  margin: 0;
}

.ala-carte-item {
  color: #dbd4c9; /* Light beige text color */
  display: flex;
  justify-content: space-between; /* Align item name and price */
  margin-bottom: 0.5rem;
  font-weight: 550; /* Regular font weight */
}

.ala-carte-item .name {
  text-align: left; /* Align item name to the left */
}

.ala-carte-item .price {
  text-align: right; /* Align price to the right */
}

/* Responsive styles */
@media (max-width: 768px) {
  .ala-carte-container {
    grid-template-columns: 1fr; /* Single column for small screens */
  }
}
