section {
  padding: 80px 13% 70px;
}
.footer {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, auto));
  gap: 3.5rem;
  position: relative;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #2e241b;
}
.footer-content {
  display: flex;
  flex-direction: column;
  align-items: center; /* 使水平居中 */
  justify-content: center; /* 使垂直居中 */
  text-align: center;
}
.footer-content h4 {
  color: #9f978f;
  margin-bottom: 1.5rem;
  font-size: 20px;
}
.footer-content img {
  width: 160px;
  margin-bottom: 10px;
}

.footer-content li {
  margin-bottom: 16px;
}

.footer-content li a {
  display: block;
  color: #9f978f;
  font-size: 15px;
  font-weight: 400;
  transition: all 0.4s ease;
}

.footer-content li a:hover {
  transform: translateY(-3px);
  color: rgb(250, 241, 230);
}

.footer-content p {
  color: #9f978f;
  font-size: 16px;
  line-height: 30px;
}

.footer-content span {
  color: #9f978f;
  font-size: 16px;
}

.icons a {
  display: inline-block;
  font-size: 40px;
  color: #9f978f;
  /* margin-right: 17px; */
  transition: all 0.4s ease;
}

.icons a:hover {
  color: rgb(250, 241, 230);
  transform: translateY(-3px);
}

@media (max-width: 1690px) {
  .section {
    padding: 50px 5% 40px;
  }
}

@media (max-width: 1120px) {
  .footer {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, auto));
    gap: 2rem;
  }
}

@media (max-width: 700px) {
  .footer {
    display: grid;
    grid-template-columns: 1fr; /* 在窄屏幕上使用單列佈局 */
    gap: 2.5rem;
  }
}

/* @media (max-width: 700px) {
  .footer {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, auto));
    gap: 2.5rem;
  }
} */
