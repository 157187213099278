/* General styles */
body {
  font-family: "Noto Serif TC", serif;
}

.blog-section {
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  margin: 20px 0;
}
.blog-title {
  text-align: center;
  font-size: 1.8rem;
  color: #331307;
  margin-bottom: 15px;
}
.blog-list {
  list-style: none;
  padding: 0;
  margin: 0;
}
.blog-list li {
  margin-bottom: 15px;
  padding: 10px;
  border-bottom: 1px solid #ddd;
}
.blog-list a {
  font-size: 1.2rem;
  color: #ae9256;
  text-decoration: none;
}
.blog-list p {
  font-size: 0.9rem;
  color: #555;
}
.blog-list a:hover {
  text-decoration: underline;
}

.home-container h2 {
  font-size: 1.8rem; /* Adjust as needed for headlines */
  color: #2e241b;
  margin-bottom: 1rem;
}

.room-description-container p {
  margin-bottom: 1.5em; /* 為段落和清單間加入空白 */
}

.feature-list {
  margin: 1.5em 0; /* 為清單上下加入空白 */
  padding-left: 20px; /* 確保清單有適當的縮進 */
}

.feature-list li {
  margin-bottom: 0.8em; /* 為清單項目加入間距 */
}

.menu-image-container img {
  width: 100%;
}
.home-container p {
  font-size: 1rem; /* Standard paragraph font size */
  line-height: 1.5;
  color: #2e241b;
}

/* Image container styles */
.home-image-container img {
  width: 100%;
  display: block;
}

/* Menu introduction section */
.menu-introduction {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
  margin: 0 auto;
  padding: 20px 0;
}

.menu-feature {
  text-align: center;
}

.menu-feature-text {
  margin-bottom: 20px;
  font-size: 1rem;
}

.menu-options {
  display: flex;
  justify-content: space-around;
  gap: 3px; /* Space between images */
  width: auto; /* Add auto margins on the sides */
  margin-left: auto;
  margin-right: auto;
}

.menu-option {
  position: relative;
  width: 100%; /* Adjust width as needed */
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  overflow: hidden;
}

.menu-option img {
  width: 100%;
  height: auto;
  filter: brightness(50%);
  transition: transform 0.3s ease-in-out, filter 0.3s ease-in-out;
}

.menu-option p {
  position: absolute;
  color: white;
  font-size: 1.2rem;
  font-weight: bold;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.6);
}

.menu-option:hover img {
  transform: scale(1.05);
  filter: brightness(75%);
}

/* Info section */
.info-section {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 0;
  padding: 20px 0;
  background-color: rgb(231, 226, 215);
}

.info-block {
  width: 45%;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.private-room-text {
  text-align: left;
  margin: 2% 5%;
  font-size: 1rem;
}

.room-description-container {
  margin: 20px 0;
}

.private-room-image img {
  width: 100%;
  height: auto;
}

/* Button link */
.button-link {
  display: inline-block;
  margin-top: 10px;
  padding: 10px 20px;
  color: white;
  background-color: #2e241b;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.button-link:hover {
  background-color: #563021;
}

/* Selected dishes section */
.selected-dishes {
  text-align: center;
  padding: 20px 0;
}

.food-grid {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  width: 100%;
  box-sizing: border-box;
  margin: 2% 0;
}

.food-box {
  position: relative;
  width: 100%;
  padding-top: 90%;
  overflow: hidden;
  box-sizing: border-box;
}

.food-box img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
  transition: transform 0.3s ease-in-out, filter 0.3s ease-in-out;
}

.food-box:hover img {
  transform: scale(1.02);
  filter: brightness(0.5);
}

.food-box .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: rgb(239, 239, 239);
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.food-box:hover .overlay {
  opacity: 1;
}

/* Responsive styles */
@media (max-width: 1200px) {
  .food-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 768px) {
  .food-grid {
    grid-template-columns: repeat(3, 1fr);
  }
  .info-section,
  .menu-introduction {
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  .info-block,
  .menu-feature,
  .menu-option {
    width: 90%;
  }
  .private-room-text,
  .menu-feature-text {
    margin: 2% 10%;
  }
  .private-room-image img,
  .menu-option img {
    width: 100%;
    margin: 0 auto;
  }
  h2 {
    font-size: 1.5rem;
  }
  p {
    font-size: 0.9rem;
  }
}

@media (max-width: 480px) {
  .food-grid {
    grid-template-columns: repeat(2, 1fr);
  }
  h2 {
    font-size: 1.2rem;
  }
  p {
    font-size: 0.8rem;
  }
}
