.brand-logo {
  text-align: center;
}
.sticky-navbar {
  position: fixed;
  top: 0;
  width: 100%;
  margin: 0 auto;
  background-color: rgb(231, 226, 215, 0.85); /* 背景色稍微透明 */
  z-index: 1000; /* 確保菜單在其他元素之上 */
  display: flex;
  justify-content: space-between; /* 標誌靠左，導航欄靠右 */
  align-items: center; /* 垂直居中對齊 */
  display: none;
}
.sticky-navbar-inner {
  width: 87%;
  margin: 0 auto; /* 水平居中 */
  display: flex;
  justify-content: space-between;
  align-items: center;
}
#booking {
  background-color: #5d3919;
  color: #e7e2d7;
  border-radius: 5px;
}
#booking:hover {
  background-color: #af9e8e;
}
.sticky-navbar .header-link-item {
  color: #5d3919;
}
.navbar-container {
  width: 100%;
}
.top-navbar {
  display: flex;
  justify-content: space-between; /* 標誌靠左，導航欄靠右 */
  align-items: center; /* 垂直居中對齊 */
  width: 87%; /* 將寬度設為87% */
  margin: 10px auto;
}
.brand-logo img {
  width: 100px;
}

.navbar {
  display: flex;
  text-align: center;
  position: relative;
  justify-content: center;
  padding-top: 10px;
  padding-bottom: 10px;
  transition: 0.3s ease-out;
}

.header-link-item {
  text-align: center;
  font-size: 1rem;
  padding: 0.5rem 1rem;
  display: inline-block;
  text-decoration: none;
  color: #5d3919;
  letter-spacing: 0.1rem;
  transition: all 0.4s ease;
}

.header-link-item:hover {
  color: white;
  color: #caaf98;
  /* transform: translateY(-3px); */
}

.header {
  background-color: #e7e2d7;
}

@media (max-width: 768px) {
  .mobile-nav-links,
  .brand-logo {
    display: block;
    width: 100%;
    text-align: center;
  }

  .top-navbar {
    flex-direction: column;
  }
  .navbar {
    padding-top: 0;
  }
  .brand-logo {
    width: 100%;
    text-align: center;
  }
}
