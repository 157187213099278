.mobile-nav-bar {
  position: fixed;
  display: none;
  right: 0;
  top: 0;
  width: 80vw;
  height: 100vh;
  z-index: 21;
  background-color: #f4e7d3;
  animation: right-to-left 0.2s ease-in forwards;
  box-shadow: -2px 0px 20px 0px #5d3919;
}

.mobile-nav-background {
  position: fixed;
  display: none;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(54, 47, 47, 0.45);
}

.mobile-nav-logo {
  font-family: serif;
  font-size: 24px;
  color: #9f978f;
}
.mobile-nav-links {
  margin: 12px 0;
}

.mobile-nav-links .nav-link {
  color: #5d3919;
  padding: 10px 0;
  display: flex;
  flex-direction: column;
  align-items: center; /* 使水平居中 */
  justify-content: center; /* 使垂直居中 */
  text-align: center;
}
.mobile-nav-links .nav-link:hover {
  color: #9f978f;
  transform: translateY(-3px);
}

.mobile-social-links-container {
  display: grid;
  grid-auto-flow: column;
  width: 80%;
  margin: 10px auto;
}

.mobile-social-links-container img {
  width: 28px;
  height: auto;
}

@media screen and (max-width: 768px) {
  .mobile-nav-bar,
  .mobile-nav-background {
    display: block;
    z-index: 9999;
  }
}

@keyframes right-to-left {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(0%);
  }
}
