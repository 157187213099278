/* General styles */
h2 {
  font-size: 0.8rem; /* Adjust as needed for headlines */
  color: #2e241b;
  margin-bottom: 1rem;
}

p {
  font-size: 1.2rem; /* Standard paragraph font size */
  line-height: 1.5;
}

.menu-title {
  text-align: center; /* 置中對齊 */
  font-size: 1.5rem; /* 調整字體大小 */
  color: #d3c3ac; /* 文字顏色 */
  padding: 2rem 0 1rem; /* 增加上下內距 */
  font-family: "Cinzel", serif; /* 與 .menu-box 標題一致的字型 */
  font-weight: 600; /* 粗體字 */
  background-color: #331307;
}

/* Menu container styles */
.menu-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 2rem;
  padding: 0 5% 0;
  background-color: #331307;
  position: relative;
  overflow: hidden;
}

.menu-info {
  display: flex;
  justify-content: flex-end; /* Align items to the right */
  align-items: center; /* Align items vertically in the center */
  flex-wrap: wrap; /* Allow wrapping to multiple lines if necessary */
  background-color: #331307;
  color: #d3c3ac;
  gap: 1rem; /* Space between items */
  padding: 2.5% 5% 2.5%;
}

.gradient-text {
  font-family: "Cactus Classical Serif", serif; /* Ensure the correct font */
  font-size: 24px;
  font-weight: bold;
  background: linear-gradient(to right, #f3dc97, #c2a777); /* Gradient colors */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.menu-box {
  background-color: rgba(
    68,
    35,
    24,
    0.8
  ); /* Add opacity to the background color */
  border: solid 0.5px #ae9256;
  border-radius: 8px;
  padding: 16px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-family: "Noto Serif TC", serif;
  font-optical-sizing: auto;
  font-weight: 580;
  font-style: normal;
  position: relative;
  z-index: 1; /* Ensure content is above the background overlay */
}

.menu-box h2 {
  color: #d3c3ac;
  margin-bottom: 1rem;
  margin-top: 1rem;
  font-size: 1.9rem;
  letter-spacing: 0.1em;
  font-family: "Cinzel", serif;
  font-weight: 600;
}

.menu-box ul {
  list-style: none;
  padding: 0;
}

.menu-box li {
  margin-bottom: 0.5rem;
  color: #d3c3ac;
  line-height: 1.7;
  font-size: 1.4rem;
  letter-spacing: 0.14em;
}

/* Responsive styles */
@media (max-width: 1200px) {
  .menu-container {
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  }
}

@media (max-width: 768px) {
  .menu-container {
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 1.5rem;
    padding: 5% 8%; /* Increase padding for smaller screens */
  }
}

@media (max-width: 480px) {
  .menu-container {
    grid-template-columns: 1fr; /* Single column for extra small screens */
    gap: 1rem;
    padding: 5% 5%; /* Further increase padding for extra small screens */
  }
}
