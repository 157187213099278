.copyright-container {
  background-color: #1d1711;
}

.copyright-container p {
  color: #9f978f;
  text-align: center;
  margin: 4px auto;
  font-size: 0.8rem;
}
