html {
  height: 100%;
}
* {
  padding: 0;
  margin: 0;
  text-decoration: none;
  list-style: none;
}

body {
  min-height: 100%;
  margin: 0;
  display: flex;
  flex-direction: column;
}
