.container {
  margin: 0;
  min-height: 100%; /* 確保容器至少高度為100% */
  display: flex;
  flex-direction: column; /* 切換主要方向為垂直 */
}

.main-content {
  flex-grow: 1; /* 允許主要內容區域填充可用空間 */
}
